<template>
  <div>
    <div class="d-flex align-items-center">
      <UserProfile :user="user" class="flex-grow-1 me-3"></UserProfile>
      <SubmitButton
        @click="requestCoaching"
        v-if="!isRequestSent"
        :text="'Request coaching'"
        :textBusy="'Requesting...'"
        :isLoading="isLoading"
        :disabled="isLoading"
        class="btn btn-primary"
      ></SubmitButton>
      <span v-else class="small text-muted fst-italic">Request sent!</span>
    </div>
    <div v-if="user.bio" class="mt-2">
      <div><strong>{{ user.firstName }}'s bio:</strong></div>
      <div v-html="user.bio"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { httpPost } from '@/core/http';

export default {
  name: 'CoachListItem',
  components: {
    UserProfile: () => import('@/components/UserProfile'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('auth', ['authUser']),
  },
  methods: {
    async requestCoaching() {
      this.isLoading = true;
      try {
        const coachId = this.user.userId;
        const athleteId = this.authUser.userId;
        await httpPost('/coaching/request', { coachId, athleteId });
        this.isRequestSent = true;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      isRequesting: false,
      isRequestSent: false,
    };
  },
};
</script>
